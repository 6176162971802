import React, { useState, useEffect} from "react";
import "./styles.scss";
import bilde from './j.png';
import boller from './boller.png'
import {Animated} from "react-animated-css";

export default function App() {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await fetch("https://lichess.org/api/user/jsalomon");
      res
        .json()
        .then(res => setData(res))
        .catch(() => {console.log("Kunne ikke hente spiller")})  
    }

    fetchData();
  }, []);

  const items = ["har bare $rating den gutten", "absolutt ikke", "", "haha nei, ligger på $rating", "burde nok vært litt kjappere for å komme seg over $rating", "den fjompenissen har bare $rating"]

  const item = items[Math.floor(Math.random() * items.length)];

  const bulletRating = data?.perfs?.bullet.rating;

  return (
    <div className="App">
      <div className="tekst">{!bulletRating ? "" : bulletRating >= 2600 ? "JA" : "NEI"}</div>
      {bulletRating && bulletRating < 2600 && <h3>{item.replace('$rating', bulletRating)}</h3>}
      <Animated animationIn="wobble" animationOut="zoomOutDown" animationInDuration={10000} animationOutDuration={10000} isVisible={true}>
        <img alt="stuff" src={bilde} />
      </Animated>
      <Animated animationIn="bounceInDown" animationOut="zoomOutDown" animationInDuration={10000} animationOutDuration={10000} isVisible={false}>
        <img alt="stuff" src={boller} />
      </Animated>
    </div>
  );
}
